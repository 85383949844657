.navbar-homepage{
  /* background: rgb(135 59 252); */
  /* background: rgb(95 70 253); */
  /* background: #5341cc; */
  /* background-image: url("/src/assets/bg-8.jpg"); */
    box-shadow: 0 0 25px rgba(0, 0, 0, .2);
  /* background: linear-gradient(135deg, rgb(86, 188, 250) 0%, #4a39aef0 100%); */
  background: linear-gradient(to right, #19012a, #320152);
  /* background: linear-gradient(135deg, #f1ebfb, #a07eff); */

  padding: 20px;
  margin: 20px;
  border-radius: 20px;
}
@media (max-width:600px) {

  .navbar-homepage{
    display: flex;
    flex-direction: column;
    padding: 0;
    padding-top: 10px;
    padding-bottom: 20px;
    margin: 0px;
    border-radius: 0px;
  }
}