/* .icon-bg {
    width: 50px; 
    height: 50px; 
    border-radius: 50%; 
    background: #000; 
    display: flex;
    align-items: center; 
    justify-content: center; 
} */

.icon-bg i {
    color: #fff; 
    font-size: px; 
}
.expertise {
  width: 90%;
  margin: auto;
  display: flex;
  flex-direction: column;
  gap: 30px;
  justify-content: center;
  align-items: center;
  padding: 20px;
  font-weight: 500;
  margin-top: 50px;
  margin-bottom: 60px;
}
  .expertise h2{
    color: black;
    font-weight: 600;
    font-size: 28px;
    display: flex;
    align-items: center;
  }
  
  .expertise-table {
    margin: auto;
    display: grid;
    place-items: center;
    grid-template-columns: 1fr 1fr 1fr;
    row-gap: 20px;
    column-gap: 20px;
    width: 100%;
  }
  
  .logo-expertise {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 10px;
    border-radius: 8px;
    place-items: center;
      }
  
  .icon-bg {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #320152;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    width: 60px;
    margin-right: 10px;
  }
  
  .calculator-2{
    color: #fff;
  }
  .icon-bg i {
    font-size: 20px;
    color: white;
  }
  .fa-file-invoice,.fa-briefcase,.fa-money-bill-wave,.fa-book,.fa-handshake,.fa-calendar-alt{
    font-size: 24px;
    color: white;
  }
  
  .logo-expertise {
    font-size: 16px;
    color: #333;
  }
  @media(max-width:980px){
    .icon-bg{
      width: 50px;
      height: 50px;
    }
    .logo-expertise{
      font-size: 14px;
    }
  }
  @media(max-width:880px){
    .icon-bg{
      width: 40px;
      height: 40px;
    }
    .logo-expertise{
      font-size: 14px;
    }
         .calculator-2 {
          font-size: 20px;
         }
      
        .fa-file-invoice,
        .fa-briefcase,
        .fa-money-bill-wave,
        .fa-book,
        .fa-handshake,
        .fa-calendar-alt {
          font-size: 20px;
          color: white;
        }
  }
  @media(max-width:820px){
    .icon-bg{
      width: 40px;
      height: 40px;
    }
    .logo-expertise{
      font-size: 14px;
      flex-direction: column;
      gap: 20px;
      text-align: center;
    }
         .calculator-2 {
           font-size: 20px;
         }
        .fa-file-invoice,
        .fa-briefcase,
        .fa-money-bill-wave,
        .fa-book,
        .fa-handshake,
        .fa-calendar-alt {
          font-size: 20px;
          color: white;
        }
  }
  @media(max-width:750px){
    .icon-bg{
      width: 50px;
      height: 50px;
    }
    .logo-expertise{
      font-size: 16px;
      flex-direction: column;
      gap: 20px;
      text-align: center;
    }
    .expertise{
      margin-bottom: 0px;
    }
    .expertise-table{
      grid-template-columns: 1fr 1fr;
    }
             .calculator-2 {
               font-size: 20px;
             }
        .fa-file-invoice,
        .fa-briefcase,
        .fa-money-bill-wave,
        .fa-book,
        .fa-handshake,
        .fa-calendar-alt {
          font-size: 20px;
          color: white;
        }
  }
  @media(max-width:600px){
    .icon-bg{
      width: 50px;
      height: 50px;
    }
    .logo-expertise{
      font-size: 14px;
      flex-direction: column;
      gap: 20px;
      text-align: center;
    }
    .expertise-table{
      grid-template-columns: 1fr 1fr;
    }
      
        .fa-file-invoice,
        .fa-briefcase,
        .fa-money-bill-wave,
        .fa-book,
        .fa-handshake,
        .fa-calendar-alt {
          font-size: 20px;
          color: white;
        }
  }
  @media(max-width:365px){
    .icon-bg{
      width: 40px;
      height: 40px;
    }
    .logo-expertise{
      font-size: 13px;
      flex-direction: column;
      gap: 20px;
      text-align: center;
    }
    .expertise-table{
      grid-template-columns: 1fr 1fr;
    }
                 .calculator-2 {
                   font-size: 20px;
                 }
        .fa-file-invoice,
        .fa-briefcase,
        .fa-money-bill-wave,
        .fa-book,
        .fa-handshake,
        .fa-calendar-alt {
          font-size: 20px;
          color: white;
        }
  }