

.easy{
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 40px;
    width: 80%;
    margin: auto;
    margin-bottom: 80px;
    margin-top: 120px;

}
.easy h1{
    font-size: 27px;
    color: #4a39aef0;
    font-weight: 600;
}
.easy-steps{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 100px;
    margin: auto;
    width: 100%;
}
.easy-bg{
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #4a39aef0;
    border-radius: 50%;
    width: 100px;
    height: 100px;

}
.easy-bg p{
    position: relative;
    top: 12%;
    left: -1%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 44px;
    color: #fff;
    font-weight: 600;
}
.easy-box{
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
    width: 100%;
    margin: auto;
}
.check1{
color: #fff;
font-size: 36px;
font-weight: 500;
}
.easy-box-heading{
    font-size: 18px;
    font-weight: 600;
    color: #646464;
    margin-bottom: 0px;
}
.easy-box-para{
    font-size: 15px;
    color: #646464;
    text-align: center;
}



@media(max-width:975px){
    .easy-bg{
        width: 80px;
        height: 80px;
    }
    .easy-bg p{
        font-size: 34px;
    }
    .easy-box-heading{
        font-size: 16px;
    }
}
@media(max-width:900px){
    .easy-bg{
        width: 110px;
        height: 110px;
    }
    .easy-bg p{
        font-size: 48px;
    }
    .easy-box-heading{
        font-size: 22px;
    }
    .easy-steps{
        display: flex;
        flex-direction: column;
        gap: 30px;
    }
        .easy-box-para {
            font-size: 16px;
      
            
        }
}

@media(max-width:600px){
    .easy-bg{
        width: 70px;
        height: 70px;
    }
    .easy-bg p{
        font-size: 34px;
    }
    .easy-box-heading{
        font-size: 22px;
    }
    .easy-steps{
        display: flex;
        flex-direction: column;
        gap: 30px;
    }
        .easy-box-para {
            font-size: 18px;
      
            
        }
}
@media(max-width:450px){
   .easy-box-para {
            font-size: 16px;
      
            
        }
}
@media(max-width:760px){
   .easy h1{
    text-align: center;
    margin: auto;
    width: 100%;
    font-size: 26px;
   }
}