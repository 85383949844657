/* Container for the signup popup */
.signup {
   display: flex;
   position: fixed;
   top: 8%;
   left: 27%;
margin: auto;
width: 45%;
height: 80%;
   flex-direction: column;
   align-items: center;
   z-index: 3000;
}
.services-checkbox{
    display: flex;
}
.sign-drop {
    width: 100% !important;
}
.sign-head{
    color: #fff;
    /* color: #524cbe; */
    font-weight: 600;
}
.received{
    font-size: 20px !important;
    text-align: center;
}

/* Signup form box */
.signup-box {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
    margin: auto;
    background-color: #fff;
    padding: 30px;
    padding-bottom: 50px;
    border-radius: 20px;
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.2);
    position: relative;
    align-items: center;
    /* background: #524cbe; */
    /* background: linear-gradient(to top, #3c7bb7 0%, #6040d2d2 100%,#3c7bb7 20%); */
    background: linear-gradient(to right, #19012a, #320152);
    
}

.sign-btn{
    font-size: 16px !important;
    width: 100% !important;
    border-radius: 10px !important;
    padding: 11px !important;
    height: unset !important;
     color: #242424a2 !important;
    }
    .sign-btn:hover{
        background: #fff !important;
    }
.signup-sub-box{
    display: flex;
    gap: 30px;
    z-index: 5000;
    width: 100%;
}
.thanks {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #fff;
}
.dropdown-menu{
    background: #fff;
}
.thanks h2{
    font-size: 42px;
    text-align: center;
}
.thanks p{
    font-size: 24px;
    font-weight: 400;
}

/* Close button */
.cross {
    position: absolute;
    top: 5px;
    right: 15px;
    font-size: 26px;
    cursor: pointer;
    /* color: #524cbe; */
    color: #fff;
}

/* Form inputs styling */
.sign-inp
 {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    box-sizing: border-box;
    outline: none;
    border-radius: 10px;
    /* border: none; */
    border: 1px solid #ffffff;
}

/* Focus effect for inputs */
.signup-box input:focus {
    outline: none;
    border: #320152;
    /* Matching your theme's color */
}

.signup-box input:focus {
    outline: none;
    /* Matching your theme's color */
}

/* Fullname section */
.fullname {
    display: flex;
    gap: 10px;
}
.form{
    display: flex;
    flex-direction: column;
    gap: 20px;
}

/* Button styling */
.sign-submit {
    width: 100%;
    /* Matching your theme */
    color: #320152;
    padding: 12px;
    margin-top: 15px;
    border: none;
    border-radius: 10px;
    font-weight: 600;

    cursor: pointer;
    font-size: 20px;
}

.signup-box button:active {
    border: 2px solid #fff;
    background: none;
    color: #fff;
    /* Slightly lighter hover color */
}
.sign-btn:focus{
    background: #fff !important;
}
.signup-block{
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
    width: 45%;
}

/* Media Queries for responsiveness */

@media (max-width: 1050px) {
   .sign-inp::placeholder{
    font-size: 15px;
   }
   .sign-btn{
    font-size: 15px !important;
   }
    
}
@media (max-width: 950px) {
   .sign-inp::placeholder{
    font-size: 15px;
   }
   .sign-btn{
    font-size: 15px !important;
   }
    
}
@media (max-width: 830px) {
   .sign-inp::placeholder{
    font-size: 14px;
   }
   .sign-btn{
    font-size: 14px !important;
   }
    
}
@media (max-width: 550px) {
   .signup{
    width: 80% !important;
    margin: auto;
    left: 10% !important;
   }
    
}
@media (max-width: 768px) {
    .signup-box {
        width: 90%;
        padding: 20px;
    }

    .signup-sub-box {
        flex-direction: column;
    }
    .signup-block{
        width: 100%;
    }
    .signup{
        width: 65%;
        left: 15%;
        top: 1%;
    }
    .sign-head{
        font-size: 32px;
    }
    .signup-box{
        gap: 5px;
    }
    .signup-sub-box{
        gap: 20px;
      
    }
          .sign-inp {
              padding: 5px;
              padding-left: 15px;
          }
          .sign-btn{
            padding: 8px !important;
            padding-left: 15px !important;
          }
          .form{
            gap: 8px;
          }
          .sign-submit{
            padding: 10px;
          }
}
@media (min-width:1300px) {
    .signup{
        width: 62%;
left: 19%;
    }
    
    }

