.navbar1{
    display: flex;
    align-items: center;
    justify-content: space-between;
width: 90%;
margin: auto;
background: #f8f4fc;
padding: 5px 20px;
margin-top: 20px;
border-radius: 15px;
}
.nav-menu li.active a::after {
    content: "";
    position: absolute;
    bottom: -5px;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: #000;
    /* Color of the underline */
}
.logo{
    width: 230px;
    height: 95px;
}
.nav-menu{
    margin-top: 5px;
    display: flex;
    list-style: none;
    gap: 50px;
    /* color: #fff; */
    font-weight: 500;
}
.nav-menu li a{
    text-decoration: none;
    color: #320152;
    font-weight: 600;
    font-size: 17px;
}
.nav-menu li {
    display: flex;
    flex-direction: column;
    gap: 5px;
}
.nav-menu li hr{
    opacity: unset;
    height: 3px;
    background: #320152;
    margin-top: 0;
    margin-bottom: 0;
    width: 80%;
    margin: auto;
}
.nav-menu li:active {
    /* color: #a07eff; */
}
.call-btn{
    padding: none;
    background: none;
    color: #2a2262;
    border: none;
    outline: none;
    font-size: 14px;
    font-weight: 600;
}
.call-btn {
    /* color: #2a2262; */
    color: #fff;
    text-decoration: none;
    font-size: 16px;
}
.calculator-1{
    color: #fff;
    font-size: 14px;
    margin-bottom: 2px;
}
.icon-btn:active {
    background: none;
    border: 2px solid #320152;
    color: #320152;
}

.icon-btn:hover .call-btn{
    color: #1c78b6df;
}
.icon-btn:active .call-btn {
        color: #320152;

}
.icon-btn:active .calculator-1{
    color: #320152;

}

.icon-btn{
    display: flex;
    gap: 8px;
    align-items: center;
    /* background: #2a2262;  */
  background: linear-gradient(to right, #19012a, #320152);
    /* background: #4a39aef0; */
    /* background: linear-gradient(to left, #3c7bb7 0%, #6040d2d2 100%); */
     color: white;
    /* background: white;
    color: #2a2262; */
    font-weight: 600;
    font-size: 1px;
    padding: 15px 17px;
border-radius: 15px;
border: none;
outline: none;
}
.nav-mob-close {
    display: none;
}
.logo4{
    display: none;
}
.nav-mob-open {
    display: none;
}
@media(max-width:1050px){

.nav-menu li a{
    font-size: 16px;
}
.nav-menu{
    gap: 35px;

}
.call-btn{
    font-size: 15px;
}
.icon-btn{
    padding: 12px 12px;
}
.navbar1{
    width: 95%;
}
.logo{
    width: 200px;
    height: 80px;
}
.call-btn a{
    font-size: 14px;
}
.calculator-1{
    font-size: 14px;
}
}
@media(max-width:910px) {
    .navbar1 {
        width: 95%;
            background: #f8f4fc;
        padding: 5px;
    }

   .icon-btn{
    display: none;
   }
   /* .logo{display: none;} */
   .logo{
    display: block;
       width: 180px;
       height: 77px;
       margin-right: 20px;
   }

    .nav-mob-open {
        display: block;
        right: 20px;
        width: 30px;
        font-size: 36px;
        margin-right: 20px;
        color: #2a2262;
    }
.nav-menu li a{
    color: #320152;
    font-weight: 600;
    font-size: 18px;
}
    .nav-mob-close {
        display: block;
        position: relative;
        top: 30px;
        left: 200px;
        font-size: 26px;
        color: #320152;
    }

    .nav-menu {
        position: fixed;
        flex-direction: column;
        align-items: start;
        top: -5px;
        gap: 40px;
        background: #fff;
        width: 250px;
        height: 100%;
        z-index: 2500;
        transition: right 0.5s;
        right: -250px;
    }

    .nav-menu li {
        font-size: 20px;
        padding-left: 30px;
        flex-direction: row;
        gap: 20px;
        color: #320152;
    }
}
@media (max-width:490px) {

    .navbar1{
        width: 95%;
        margin-top: 10px;
    }
        
    
        .nav-mob-open {
            font-size: 32px;
        }
}
@media (max-width:600px) {
    .nav-mob-open {
            font-size: 34px;
        }
        .logo{
            width: 180px;
            height: 75px;
        }
}
