.scrollbar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: #fff;
    z-index: 2000;
    color: #444;
    height: 95px;
    border-bottom-left-radius: 100%;
    border-bottom-right-radius: 100%;
    box-shadow: 0 0 10px rgba(90, 53, 53, .2);
  
    display: flex;
    align-items: center;
    padding: 0 20px;
    box-sizing: border-box;
    opacity: 1;
  
    visibility: visible;
   
}


.scrollbar.hide {
    opacity: 0;
   
    visibility: hidden;
 
}


.scrollbar.show {
    padding-top: 10px;
    display: flex;
    flex-direction: row;
    opacity: 1;
    
    visibility: visible;
    height: 150px;
    justify-content: space-around;
    align-items: start;
  
}


.logo-scroll {
    width: 230px;
    height: 95px;
    margin-top: 10px;
    margin-right: 30px;
}


/* .menu-btn {
    display: flex;
    align-items: center;
} */

.navmenu {
   list-style: none;
   display: flex;
   font-weight: 600;
   margin-bottom: 20px;
   margin-right: 5px;
}
.navmenu li{
   
}
.navmenu.show {
    right: 0;
}
.navmenu li hr{
    width: 70%;
    margin: 0;
    height: 3px;
    opacity: unset;
background: #320152;
margin: auto;
}
.navmenu li {
    display: flex;
        flex-direction: column;
        gap: 5px;
    margin: 10px 10px;
}

.navmenu a {
    color: #320152;
    text-decoration: none;
    padding: 10px 10px;
    padding-bottom: 0px;
    font-size: 18px;
    display: block;
    transition: background-color 0.3s, border-radius 0.3s;
}

.navmenu a:hover {
    border-radius: 20px;
}

/* Call-to-action button styles */
.btn-call {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
}
.btn-call p{
    display: flex;
    gap: 5px;
    color: #320152;
    margin-top:5px;
}

.scroll-btn1 {
    height: 55px;
    display: flex;
    gap: 8px;
    align-items: center;
    /* background: #4a39aef0; */
    background: linear-gradient(to right, #19012a, #320152);
    /* background: linear-gradient(to left, #6040d2d2 0%, #43a1c4 80%); */
    color: white;
    font-weight: 600;
    font-size: 14px;
    padding: 15px 17px;
    border-radius: 15px;
    border: none;
    outline: none;  
}
.calculator-4{
    margin-bottom: 2px;
}
.scroll-btn1:active {
    position: relative;
    background: none;
    color: #320152;
    padding: 8px 16px;
    /* Adjust padding if necessary */
    border: 2px solid transparent;
    /* Initially transparent to show gradient behind */
}

.scroll-btn1:active::before {
    content: "";
    position: absolute;
    top: -2px;
    /* Adjust to align with the border */
    left: -2px;
    right: -2px;
    bottom: -2px;
    border-radius: inherit;
    padding: 2px;
    /* This defines the border thickness */
    /* background: linear-gradient(to left, #6040d2d2 0%, #43a1c4 80%); */
    background: #320152;
    
    -webkit-mask:
        linear-gradient(#fff 0 0) content-box,
        linear-gradient(#fff 0 0);
    mask:
        linear-gradient(#fff 0 0) content-box,
        linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
    /* This creates the effect of a gradient border */
    z-index: -1;
    /* Make sure the border stays behind the content */
}
.scroll-btn1:active .scroll-qoute-btn {
    color: #320152;
    
}


.scroll-qoute-btn {
    padding: none;
    background: none;
    color: white;
    border: none;
    outline: none;
    font-size: 16px;
    font-weight: 600;
}

.btn-call p span {
    font-size: 18px;
    font-weight: 600;
}

.nav-close {
    display: none;
}
.nav-open{
    display: none;
}
.menu-btn{
    display: flex;
    align-items: center;
}
@media (max-width: 980px) {
    .logo-scroll{
        margin-top: 0px;
    }
    .navmenu a{
        font-size: 16px;
    }
    .scroll-btn1{
        height: unset;
        width: fit-content;
    }
   
}
@media (max-width: 955px) {
    .btn-call p span{
        font-size: 16px;
    }
         .scroll-qoute-btn {
             font-size: 12px;
         }
         .btn-call p{
            font-size: 13px;
         }
         .logo-scroll{
            margin-right: 0px;
         }
         .navmenu a{
            padding: 10px 5px;
         }
         .navmenu ul{
            margin-bottom: 0px;
         }
         .navmenu{
            margin-bottom: 0px;
         }
}

@media (max-width: 900px) {
    .scrollbar {
        height: auto;
        flex-direction: column;
        padding: 10px;
    }
    .navmenu li hr{
        display: none;
    }
    .btn-call{
        margin-top: 0px;
        align-items: center;
        width: 100%;
    }
    .menu-btn{
        align-items: center;
        width: 50%;
    }
    .scroll-btn1{
        align-items: center;
        height: unset;
        width: unset;
        padding: 12px 16px;
        margin-top: 10px;
    }
    .scroll-qoute-btn{
        font-size: 16px;
    }
    .btn-call p span{
        font-size: 17px;
    }
    .scrollbar.show{
        gap: 7%;
        justify-content: unset;
        padding-top: 10px;
        
    }

    .navmenu {
        
        position: fixed;
            top: 95px;
            right: -250px;
            width: 250px;
            height: calc(100vh - 95px);
            background: #fff;
            z-index: 1000;
            transition: right 0.3s ease;
            list-style: none;
            margin: 0;
            padding: 0;
            display: flex;
            flex-direction: column;
    }

    .navmenu.show {
        top: 0;
        right: 0;
        height: 100vh;
        padding-top: 40px;
    }
    

    .nav-open {
        display: block;
       margin-top: 17px;
       position: absolute;
        right: 20px;
        top: 5px;
       margin-right: 10px;
        font-size: 36px;
        color: #2a2262;
        cursor: pointer;
    }
    .btn-call{
        margin-right: 50px;
    }

    .nav-close {
        display: block;
        position: absolute;
        top: 20px;
        right: 20px;
        font-size: 26px;
        cursor: pointer;
        color: #320152;
    }
    .scrollbar.show{
        height: 100px;
    }.logo-scroll{
        width: 180px;
        height: 75px;
        transform: translateY(-5px);
        margin-top: 0px;
        margin-right: 0px;
        margin-left: 20px;
    }
    
}
@media (max-width:600px) {
    .logo-scroll{
        width: 160px;
        height: 70px;
        margin-top: 0px;
        margin-left: 0px;
        transform: translateY(-10px);
    }
    .scroll-btn1{
        height: auto;
        align-items: center;
        padding: 13px 17px;
        font-size: 14px;
        margin-top: 0px;
    }
    .scroll-qoute-btn{
        font-size:15px;
    }
    .nav-open{
        font-size: 32px;
        margin-top: 7px;
    }
    .btn-call{
        margin-right: 20px;
        font-size: 14px;
    }
    
    .scrollbar.show{
        height: 90px;
    }
          .btn-call p span {
              font-size: 15px;
          }
}
@media (max-width:550px) {
    .scrollbar.show{
        gap: 5%;
        height: 90px;
    }
}
@media (max-width:450px) {
    .logo-scroll{
        display: none;
    }
    .menu-btn{
        width: 80%;
    }
        .scroll-btn1 {
            height: auto;
            align-items: center;
            padding: 12px 15px;
            font-size: 14px;
        }
        .btn-call p span{
            font-size:16px ;
        }
.btn-call{
    align-items: center;
    justify-content: center;
margin: auto;
margin-left: 15%;
}
.nav-open{
    margin-top: 5px;
}
.scrollbar.show{
    height: 90px;
}
  .btn-call p span {
      font-size: 14px;
  }
}