

.service{
    margin-top: 100px;
    margin-bottom: 140px;
    display: flex;
width: 100%;
margin: auto;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 20;
}

.card-container{
    width: 90%;
    margin-top: 40px;
    display: grid;
    column-gap: 50px;
    align-items: center;
    justify-content: center;
    grid-template-columns: 1fr 1fr 1fr;
    row-gap: 40px;
margin: auto;
}

.image-box img{
    width: 60%;
  margin: auto;
}
.image-box{
    width: 100%;
     background: #320152;;
     border-radius: 20px;
    display: flex; 
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
}

.image-box img{
    width: 60%;
  margin: auto;
}
.image-box{
    width: 100%;
            background: linear-gradient(to right, #19012a, #320152);
     border-radius: 20px;
    display: flex; 
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
}
.image-box1 img{
    width: 60%;
  margin: auto;
}
.image-box1{
    box-shadow: 0 0 25px rgba(0, 0, 0, .07);
    width: 100%;
     border-radius: 20px;
    display: flex; 
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
}
.service-heading{
    margin-bottom: 0px;
    font-size: 44px;
    color: black;
    font-weight: 600;
    width: 90%;
    text-align: center;
}
.service-para{
    font-size: 22px;
    font-weight: 500;
    width: 50%;
    text-align: center;
    margin-top: 10px;
    color: #646464;
    margin-bottom: 40px;
}
.card-link{
    align-items: center;
    justify-content: center;
    display: flex;
    text-decoration: none;
    gap: 10px;
    color: #320152;
    font-weight: 500;
}
.card-link:active{
    transform: scale(1.05);
}
.service-card{
   /* border: 6px solid #4a39aef0; */
    box-shadow: 0 0 25px rgba(0, 0, 0, .07);
        background: #fff;
        border-radius: 30px;
    width: 100%;
    margin: auto;
    height: auto;
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
}
#portrait{
    width: 200px;
}
.service-card p{
    margin-bottom: 0px;
}
.card-heading{
    color: black;
    font-size: 22px;
    font-weight: 500;
    text-align: center;
}
.card-para{
    /* background: #3b3080eb; */
    /* background: #6237a0; */
    text-align:center;
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
    padding: 20px ;
    padding-top: 0px;
    border-radius: 23px;
    height: auto;
    /* border-top-left-radius: 50px;
       
        border-top-left-radius: 50px; */
    color: #646464;
    /* color: white; */
}

@media (max-width:1050px) {
    .card-container{
        grid-template-columns: 1fr 1fr;
        column-gap: 40px;
    }
    .service-card{
        width: 90%;
    }
}
@media (max-width:750px) {
    .card-container{
        grid-template-columns: 1fr ;
        column-gap: 40px;
    }
    .service-heading{
        text-align: center;
    }
    .service-para{
        font-size: 18px;
        width: 95%;
    }
    .service-card{
        width: 95%;
    }
    .card-para{
        text-align: left;
    }
}