.pricing-box{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    width: 85%;
    margin: auto;
    column-gap: 20px;
}
.pricepage-heading{
    font-size: 54px;
    text-align: center
    ;
    color: black;
    width: 95%;
}
.pricepage-heading2{
    font-size: 44px;
    text-align: center
    ;
    color: black;
}
.compare-para{
    font-size: 20px;
}
.pricepage-para{
    font-size: 20px;
    text-align: center;
    width: 70%;
}
.pricing-page{
    margin-top: 60px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 60px;
}
.why-head{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 60px;
}
.container-img{
    width: 400px;
}
.container1{
    display: flex;
    align-items: center;
    width: 80%;
    margin: auto;
    justify-content: space-between;
}
.container2{
    display: flex;
    align-items: center;
    width: 80%;
    margin: auto;
    justify-content: space-between;
}
.container0{
    display: flex;
    align-items: center;
    width: 80%;
    margin: auto;
    justify-content: space-between;
}
.container0-left{
    width: 50%;
    
}
.container0-right{
    display: flex;
    align-items: end;
    justify-content: end;
    width: 40%;

}
.container1-right{
    width: 70%;
}
.container1-left{
    width: 80%;
}
.container2-right{
    width: 30%;
}
.container2-left{
    width: 50%;
}
.pricepage-choose{
display: flex;
flex-direction: column;
gap: 30px;
}
.container-heading{
    color: black;
    font-weight: 500;
    font-size: 40px;

}
.container-choose-para{
    font-size: 20px;
}

@media (max-width:900px) {
    .pricing-box{
        grid-template-columns: 1fr;
        row-gap: 40px;
    }
    .container0{
        flex-direction: column-reverse;
        width: 90%;
        gap: 60px;
    }
    .container0-right{
        align-items: center;
        width: 100%;
        justify-content: center;

    }
    .container-img{
        width: 100%;
    }
    .container0-left{
align-items: center;
width: 100% !important;
justify-content: center;
text-align: center;
    }
    .container1{
        flex-direction: column;
        width: 90%;
        align-items: center;
        gap: 40px;
        text-align: center;
    }
    .container1-left{
        width: 100%;
        display: flex;
        align-items: center;
    }
    .pricepage-choose{
        gap: 50px;
    }
    .container1-right{
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
    }
    .container2{
        flex-direction: column-reverse;
        width: 90%;
        gap: 40px;
        text-align: center;
    }
    .container2-left{
        width: 100%;
    }
    .container2-right{
        display: flex;
        align-items: center;
        width: 100%;
    }
    .container-img{
        margin: auto;
    }
    .container-choose-para{
        text-align: justify;
    }
}